import { Observable } from 'rxjs';
import { IogHttpService } from 'src/app/security/iog-http.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { IRegionAreasEdit, IRegionGroup } from 'src/app/core/models/RegionGroupDTO';

@Injectable({
  providedIn: 'root'
})
export class RegionGroupService {
  static baseUrl: string = environment.apiUrlBack + '/region-groups/'

  constructor(private iogHttp: IogHttpService) { }


  getAllRegions(): Observable<IRegionGroup[]> {
    return this.iogHttp.get<IRegionGroup[]>(RegionGroupService.baseUrl);
  }

  getRegionById(regionId: string): Observable<IRegionGroup> {
    return this.iogHttp.get<IRegionGroup>(RegionGroupService.baseUrl + `by-id?id=${regionId}`);
  }


  createRegionGroup(regionGroup: IRegionGroup): Observable<IRegionGroup> {
    return this.iogHttp.post<IRegionGroup>(RegionGroupService.baseUrl, regionGroup);
  }

  editRegionGroup(regionGroup: IRegionGroup): Observable<IRegionGroup> {
    return this.iogHttp.put<IRegionGroup>(RegionGroupService.baseUrl, regionGroup);
  }


  deleRegionGroup(regionGroup: IRegionGroup): Observable<IRegionGroup> {
    return this.iogHttp.delete<IRegionGroup>(RegionGroupService.baseUrl + `delete-by-id/${regionGroup.regionId}`);
  }


  addAreaToRegion(areas: IRegionAreasEdit) {
    return this.iogHttp.post(RegionGroupService.baseUrl + 'add-transformer-group-into-region', areas);
  }

  removeAreaOfRegion(areas: IRegionAreasEdit) {
    return this.iogHttp.put(RegionGroupService.baseUrl + 'remove-transformer-group-from-region', areas);
  }


}
