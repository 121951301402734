import { environment } from 'src/environments/environment';
import { IProcessCreatedResponse, ProcessType } from './../../core/models/ClusterRelayActionDTO';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IProcessCreate } from 'src/app/core/models/ClusterRelayActionDTO';

import { IogHttpService } from 'src/app/security/iog-http.service';
import { IPaginationResponseDTO } from 'src/app/core/models/PaginationResponseDTO';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {
  static url: string = environment.apiUrlBack + '/processes/';
  static commandUrl: string = environment.apiUrlBack + '/commands/';

  constructor(private httpService: IogHttpService) { }


  /**
   * pesquisa todos os processos disponiveis no iogServices
   * @returns lista de todos processos
   */
  getAllProcess(page: number = 1, processType: ProcessType = ProcessType.MassCommand, searchString: string = ''): Observable<IPaginationResponseDTO> {
    return this.httpService.get<IPaginationResponseDTO>(ProcessService.url + `?pageSize=1&sortOrder=updatedAt-desc&page=${page}&processType=${processType}&searchString=${searchString}`);
  }

  /**
   * cria um novo processo a ser executado
   * @param process {IProcessCreate} processo contendo os dados a serem criados
   * @returns 
   */
  createNewProcess(process: IProcessCreate): Observable<IProcessCreatedResponse> {
    return this.httpService.post<IProcessCreatedResponse>(ProcessService.commandUrl + `mass-command`, process);
  }

  getProcessBySerial(serial: string, page: number = 1, sortOrder: string = 'updatedAt-desc', pageSize: number = 1, searchString: string=''): Observable<IPaginationResponseDTO> {
    return this.httpService.get<IPaginationResponseDTO>(ProcessService.url + serial + `?page=${page}&sortOrder=${sortOrder}&pageSize=${pageSize}&searchString=${searchString}`)
  }
}
