interface IStatistics {
  added: number;
  failed: number;
  addPercentual: number;
  failedPercentual: number;
}

export class StatisticsDTO implements IStatistics {
  added: number;
  failed: number;
  failedPercentual: number;
  addPercentual: number;
}
