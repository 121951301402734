import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IAddMetersModelsDTO } from './../../core/models/BalanceDTO';
import { ITransformerDTO, IUpdateClientResponse } from './../../core/models/TransformerDTO';
import { IogHttpService } from 'src/app/security/iog-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransformersService {

  static url = environment.apiUrlBack + '/transformer-models';

  constructor(private iogHttp: IogHttpService) { }


  getAllTransformer(): Observable<ITransformerDTO[]> {
    return this.iogHttp.get<ITransformerDTO[]>(TransformersService.url);
  }

  getTransformerById(transformerId: string): Observable<ITransformerDTO> {
    return this.iogHttp.get<ITransformerDTO>(TransformersService.url + `/by-id?id=${transformerId}`);
  }


  createTransformer(transformer: ITransformerDTO) {
    return this.iogHttp.post(TransformersService.url, transformer);
  }

  editTransformer(transformer: ITransformerDTO) {
    return this.iogHttp.put(TransformersService.url, transformer);
  }

  addDeviceToTransformer(devices: IAddMetersModelsDTO): Observable<IUpdateClientResponse> {
    return this.iogHttp.put<IUpdateClientResponse>(TransformersService.url + '/add-meters-on-list', devices);
  }

  removeDeviceFromTransformer(devices: IAddMetersModelsDTO): Observable<IUpdateClientResponse> {
    return this.iogHttp.put<IUpdateClientResponse>(TransformersService.url + '/remove-meters-on-list', devices)
  }

  deleteTransformer(transformer: ITransformerDTO) {
    return this.iogHttp.delete(TransformersService.url + `/delete-by-id/${transformer.transformerId}`);
  }


}
