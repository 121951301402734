import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { IogHttpService } from 'src/app/security/iog-http.service';
import { ITransformerGroup, ITransformerGroupEditDevice } from 'src/app/core/models/TransformerGroupDTO';

@Injectable({
  providedIn: 'root'
})

export class TransformerGroupService {
  s
  static baseUrl: string = environment.apiUrlBack + '/transformer-groups/';

  constructor(private iogHttp: IogHttpService) { }


  public getAllGroups(): Observable<ITransformerGroup[]> {
    return this.iogHttp.get<ITransformerGroup[]>(TransformerGroupService.baseUrl);
  }


  createGroup(group: ITransformerGroup): Observable<ITransformerGroup> {
    return this.iogHttp.post<ITransformerGroup>(TransformerGroupService.baseUrl, group);
  }


  editGroup(group: ITransformerGroup): Observable<ITransformerGroup> {
    return this.iogHttp.put<ITransformerGroup>(TransformerGroupService.baseUrl, group);
  }

  getGroupById(groupId: string): Observable<ITransformerGroup> {
    return this.iogHttp.get<ITransformerGroup>(TransformerGroupService.baseUrl + `by-id?id=${groupId}`);
  }

  deleteGroup(group: ITransformerGroup) {
    return this.iogHttp.delete(TransformerGroupService.baseUrl + `delete-by-id/${group.groupId}`);
  }

  addTransformerToGroup(model: ITransformerGroupEditDevice) {
    return this.iogHttp.post(TransformerGroupService.baseUrl + 'add-transformer-to-transformer-group', model)
  }

  removeTransformer(model: ITransformerGroupEditDevice) {
    return this.iogHttp.put(TransformerGroupService.baseUrl + 'remove-transformer-from-transformer-group', model)
  }

}
